

  .card_settings-nav-link {
    color:rgb(0, 0, 0) !important
  }

  .card_settings-nav-link.active{
    background-color: rgb(204, 204, 204) !important;
  }

  .card_settings-nav{
    color:rgb(29, 29, 28) !important
    
  }

  .card_settings-nav.active{
    background-color: rgb(112, 111, 111) !important;
  }

  .modal_settings {
    width: 750px;
    max-width: 750px;
   
  }

  .body_settings {
    height: 100%;
    width: 100%;
    
}

.card_settings {
  height: 300px;
  width: 100%;
  overflow: auto;
}

.card_settings_header {
  color:rgb(0, 0, 0) !important
}

