.ionames {
    background-color: darkslategray;
    z-index: 1;
    position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  padding:0px;
  
}

.label {
    display: block !important; 
    padding:0px;
    
}

.form-control, .form-label, .form-check-label{
    font-size: 14px;
    padding:0px;
  }

.btnUp {
  position: relative;
  font-size: 14px;
  display: block !important; 
  padding: 0px 0px;
  top: 30px;
  height: 30px;
  width: 40px;
 text-align: center;
 background-color: darkslategray;
}
.btnUp:hover{
  background-color: rgb(234, 182, 72);
}