.bar {
    background-color: darkslategray;
    height: 40px;
    padding-left: 10px;
}

.formLabel {

   
    padding-left: 10px;
    color: white;
    display: block !important; 
    margin-top: 0rem !important; 
}

.formCheck {
    display: flex;
    justify-content: center;
    align-items: center;

}