

  .card_buildingtree {
    background-color: rgb(95, 94, 94);
    height: 500px;

    color:chocolate;
  }

  .card_mods_body{
    overflow-y: scroll;
  }

  .treeview_buildingtree {
    font-size: 10px;
    
  }

  .treeitem_buildingtree {
    font-size: 7px;
    
  }

  .nav-link {
    color:rgb(243, 186, 13) !important;
    font-size: 14px;
  }

  .building-nav{
    color:rgb(243, 186, 13) !important;
    height: 20px;
    padding-top: 1px !important;
  }

  .building-nav.active{
    background-color: rgb(112, 111, 111) !important;
  }

  .form-control, .form-label, .form-check-label, .btn-primary, .btn-secondary{
    font-size: 14px;
  
  }

