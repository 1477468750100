table {
    font-size: 12px;
}
#card_overzicht {
    background-color: rgb(68, 68, 68);
    height: 500px;
    
    color:chocolate;
  }

  .totals {
    background-color: rgb(36, 36, 36);

  text-align: right;
}

.formLabel_overzicht {

   
  padding-left: 10px;
  color: white;
  display: block !important; 
  margin-top: 0rem !important; 
}

.formCheck_overzicht {
  display: flex;
  

}