

  .card_buildingtree {
    background-color: rgb(68, 68, 68);
    height: 500px;
    color:chocolate;
  }

  .nav-link {
    color:rgb(243, 186, 13) !important
  }

  .building-nav{
    color:rgb(243, 186, 13) !important
    
  }

  .building-nav.active{
    background-color: rgb(112, 111, 111) !important;
  }

  .contextm {
    background-color: rgb(36, 36, 36) !important;
    border: 1px solid white;
    z-index: 10;
    height: 120px;
    width: 150px;
    font-size: 14px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .chane_name {
    background-color: rgb(36, 36, 36) !important;
    border: 1px solid white;
    z-index: 10;
    height: 150px;
    width: 250px;
    font-size: 14px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .chane_icon {
    background-color: rgb(36, 36, 36) !important;
    border: 1px solid white;
    z-index: 10;
    height: 300px;
    width: 250px;
    font-size: 14px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .iconCheck {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 50px;
    
}