.formLabel1 { 
    padding-left: 10px;
    color: rgb(0, 0, 0);
    display: block !important; 
    margin-top: 0rem !important; 
}

.formCheck1 {
    display: flex;
    
    align-items: left;
}