.projectsFrom {
    height: 400px;
    width: 450px;
    
}

.projectsOptions {
    font-size: small !important;
    width: 700px;
    height: 500px;
    overflow-x: auto;
    overflow-y: auto;
}

.modal_projects {
    width: 750px;
    max-width: 750px;
   
  }

.projectsTableRow {
  font-size: 0.65rem;
}
.projectsTableHeader {
  font-size: 0.65rem;
}

input[type='text'], input[type='password'], button, textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}
  
  .clickable-row {
    cursor: pointer;
    height: 25px;
    padding-left: 10px;
    
  }
  
  .clickable-row:hover {
    background-color: rgb(255, 225, 127);
  }
  
  .selected, .selected:hover {
    background-color: rgb(246, 182, 5);
  }