.nav-bar {
    background-color: black;
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  
}

.nav-container {
  width: 100%;
  
}

.navbar {
  padding-top: 0%;
  padding-bottom: 0%;
}

* {font-size: 18px;}