body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}

@media (min-width: 1400px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 5000px !important;
	}
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-family: 'Nunito', sans-serif;
	font-size: 22px;
	color: #fff;
}



.App {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 1rem 0.5rem;

}

section {
	width: 100%;
	max-width: 420px;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1rem;
	background-color: rgba(0, 0, 0, 0.4);
}

form {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	flex-grow: 1;
	padding-bottom: 1rem;
}

a,
a:visited {
	color: #fff;
}

input[type='text'],
input[type='password'],
button,
textarea {
	font-family: 'Nunito', sans-serif;
	font-size: 22px;
	padding: 0.25rem;
	border-radius: 0.5rem;
}

label,
button {
	margin-top: 1rem;
}

button {
	padding: 0.5rem;
}

.instructions {
	font-size: 0.75rem;
	border-radius: 0.5rem;
	background: #000;
	color: #fff;
	padding: 0.25rem;
	position: relative;
	bottom: -10px;
}

.instructions > svg {
	margin-right: 0.25rem;
}

.offscreen {
	position: absolute;
	left: -9999px;
}

.hide {
	display: none;
}

.valid {
	color: limegreen;
	margin-left: 0.25rem;
}

.invalid {
	color: red;
	margin-left: 0.25rem;
}

.errmsg {
	background-color: lightpink;
	color: firebrick;
	font-weight: bold;
	padding: 0.5rem;
	margin-bottom: 0.5rem;
}

.line {
	display: inline-block;
}